import { Injectable } from '@angular/core';
import { ApiService } from '../api.service';
import { FilterChip, FilterConfigItem, FilterItem } from '../../models/filters.model';
import * as moment from 'moment';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { filter, map } from 'rxjs/operators';
import {
  FIXED_FILTERVALUES_MOCK,
  FIXED_FILTERVALUES_MOCK_ONLY_CURR,
  FIXED_FILTERVALUES_MOCK_ONLY_PREV,
  OTHER_PAGE_FILTERS,
  RENEWAL_FILTERS,
  FILTERS_CONFIG,
  FILTER_COUNT_DASHLET_CONFIG
} from '../../constants/filters-config';
import { Router } from '@angular/router';
import { PAGES_TAB, DASHBOARD_NAME } from '../../constants/common-constants';
import { SavedFilters } from '../../models/saved-filters.model';
import { AppConfig } from 'src/app/app.config';
import { levelsConfigs } from '../../constants/levels-config';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  constructor(private apiService: ApiService, private route: Router, private http: HttpClient,
    private appConfig: AppConfig,
  ) {
    // this.setFilterValuesAndSelections(FILTERS_CONFIG);
    // this.getFixedFiltersValues();
  }

  // * Getter and Setter functions for the filters */

  // To store all filters dropdown data in filter panel
  private _filtersData = {};
  private set filtersData(val) {
    this._filtersData = val;
    this.filterValues.next(this._filtersData);
  }
  private get filtersData() {
    return JSON.parse(JSON.stringify(this._filtersData));
  }

  public yearValuesListForCYAndFY = '';
  public FYCYDates = {
    startDate: '',
    endDate: ''
  }
  // To set all the filter selection in the filter panel
  private _filterSelection = null;
  private set filterSelection(val) {
    this._filterSelection = val;
    this.selectedFilter.next(JSON.parse(JSON.stringify(this._filterSelection)));
  }
  private get filterSelection() {
    return JSON.parse(JSON.stringify(this._filterSelection));
  }

  // To set the renewal page is active or not
  private _isRenewalPage = false;
  set isRenewalPage(val: boolean) {
    this._isRenewalPage = val;
  }
  get isRenewalPage() {
    return this._isRenewalPage;
  }

  // To keep all the saved filters list for current page
  private _savedFilters;
  set savedFilters(filters) {
    this._savedFilters = JSON.parse(JSON.stringify(filters));
    this.savedFilterList.next(JSON.parse(JSON.stringify(filters)));
  }
  get savedFilters() {
    return JSON.parse(JSON.stringify(this._savedFilters));
  }

  // To keep the current selected filter set
  private _selectedFilterSetName;
  set selectedFilterSetName(name) {
    this._selectedFilterSetName = name;
    this.selectedSavedFilter.next(name);
    if (this.savedFilters) {
      this.savedFilters = this._savedFilters;
    }
  }
  get selectedFilterSetName() {
    return this._selectedFilterSetName;
  }

  // To keep filter groups updated
  private _filterGroups = FILTERS_CONFIG;
  set filterGroups(groups) {
    this._filterGroups = groups;
    this.updatedFilterGroups.next(groups);
  }
  get filterGroups() {
    return this._filterGroups;
  }

  // * Getter and Setter functions for the filters */

  // Private variables to be used in payload
  public _dashboardName = DASHBOARD_NAME;
  public _pageName = 'Filters';
  public _dashletType = 'CrossFilter';

  // Other private variable
  private _initialFilters = {}; // For keeping initial filters when resetting values
  private _selectedFilters = {}; // Selected filters set to be used in API payload
  private _renewalSavedList = []; // Saved list for renewal page
  private _nonRenewalSavedList = []; // Saved list for non renewal page
  public _dateRangeEndDate; // Date range start
  public _dateRangeStartDate; // Date range end
  dateData;
  yearList;
  // Observables to be used to update the data in components
  /*
  filterOpened - open and close filter panel
  selectedFilter - the selected filter for filter section
  savedFilterList - list of saved filters sets for current page
  selectedSavedFilter - selected saved set from saved filter set
  appliedFilter - applied filters for chips section
  updatedFilterGroups - filter groups in filter panel
  filterValues - filter values to be showed in filters dropdown
  filtersLoaded - boolean to show filter panels
  filterType -
  updateDashletData - flag to update the dashlet data when filters are applied
  globalSearchKey - for global seearch
  filterCountLoading - for showing loader when filter count is loading
  renewalMonthssList - to get months list to be showed in renewals
  */
  public filterOpened: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public savedFilterSelectionChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public selectedFilter: BehaviorSubject<any> = new BehaviorSubject(null);
  public savedFilterList: BehaviorSubject<any> = new BehaviorSubject(null);
  public selectedSavedFilter: BehaviorSubject<any> = new BehaviorSubject(null);
  public appliedFilter: BehaviorSubject<any> = new BehaviorSubject(null);
  public updatedFilterGroups: BehaviorSubject<any> = new BehaviorSubject(FILTERS_CONFIG);
  public filterValues: BehaviorSubject<any> = new BehaviorSubject({});
  public filtersLoaded: BehaviorSubject<{ status: boolean }> = new BehaviorSubject({ status: false });
  // public filterType: BehaviorSubject<any> = new BehaviorSubject([]);
  public updateDashletData: BehaviorSubject<any> = new BehaviorSubject({ type: false });
  public globalSearchKey: BehaviorSubject<any> = new BehaviorSubject('');
  public filterCountLoading: BehaviorSubject<{ status: boolean }> = new BehaviorSubject({ status: false });
  public renewalMonthsList: BehaviorSubject<any> = new BehaviorSubject([]);
  public filterCountSub: BehaviorSubject<string> = new BehaviorSubject(null);
  public nextDaysValue: BehaviorSubject<number> = new BehaviorSubject(null);
  public currencyUpdated: BehaviorSubject<boolean> = new BehaviorSubject(null);
  public chipsUpdated: BehaviorSubject<Object> = new BehaviorSubject(null);
  public strongerTogetherValueChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  public isSaveFilterTriggered = false;
  public strongerTogetherReasonChanged: BehaviorSubject<any> = new BehaviorSubject(null);
  public resetBreadCrumbForAllDashlets: BehaviorSubject<Object> = new BehaviorSubject(null);
  public goBackLevelForDashlet: BehaviorSubject<Object> = new BehaviorSubject(null);
  
  private levelConfigs = levelsConfigs;
  showTwoRateOptions = this.parseToBoolean(this.appConfig['showTwoRateOptions'])
  showPreviousYearOnly = this.parseToBoolean(this.appConfig['showPreviousYearOnly'])
  mapRateTypeFYTo = this.appConfig['mapRateTypeFYTo']

  parseToBoolean(value) {
    if (value) {
      return value.toString().toLowerCase() === 'true';
    }
    return false;
  }

  // Get the optional filters values for optional filter
  getOptionalFiltersValues(config, entityType) {
    let appliedFilters = this.getAppliedFilters(entityType);
    appliedFilters = this.getFYRate(appliedFilters);
    let payload = {
      userEmail: localStorage.getItem('userEmail'),
      dashboard: this._dashboardName,
      page: this._pageName,
      dashlet: this._dashletType,
      queryTypeId: 4,
      startDate: this._dateRangeStartDate,
      endDate: this._dateRangeEndDate,
      entityType,
      ...appliedFilters
    };
    payload = { ...config, ...payload };
    // Below condition is added to check if dropdown specific searchKey isnt there, then to use global key
    if (!payload['searchKey'] && this.globalSearchKey.value && this.globalSearchKey.value.length) {
      payload['searchKey'] = this.globalSearchKey.value;
    }

    //For Producer State Filter, we need pass the producer country value
    if (entityType === 'ProducerState') {
      let staticPayload = [
        { key: 'ProducerCountry', value: `"${this.appConfig['countryNames'].US}","Canada"`, operator: 'IN' },

      ];
      payload.appliedFilters = [...payload.appliedFilters, ...staticPayload];
    }

    //Remove Producer Filter for Client Filter
    if (entityType === 'ClientRegion' || entityType === 'ClientCountry') {

      let removedPayload = payload.appliedFilters.filter(filterObj => {
        return !["ProducerRegion", "ProducerCountry", "ProducerState"].includes(filterObj.key);
      })
      payload.appliedFilters = [...removedPayload];
      //Remove  Client Filters from the payload when the user selected the Producer related filters
    } else if (entityType === 'ProducerRegion' || entityType === 'ProducerCountry' || entityType === 'ProducerState') {
      let removedPayload = payload.appliedFilters.filter(filterObj => {
        return !["ClientRegion", "ClientCountry",].includes(filterObj.key);
      })
      payload.appliedFilters = [...removedPayload];
    }

    this.apiService.post('fixedFilters', payload).subscribe(data => this.setOptionalFilterValues(entityType, data));
  }

  setSavedFilterSelectionChanged(value) {
    this.savedFilterSelectionChanged.next(value);
  }

  getSavedFilterSelectionChanged() {
    return this.savedFilterSelectionChanged.asObservable();
  }

  getCurrencyValues() {
    const entityType = 'ReportingCurrency';
    const { startDate, endDate } = this.getLastDaysYearConfig();
    const initialFilters = JSON.parse(JSON.stringify(this._initialFilters));
    const payload = {
      DateType: initialFilters.DateType.value[0].key,
      TimePeriod: initialFilters.TimePeriod.value[0].key,
      appliedFilters: {
        RateType: `"${initialFilters.RateType.value[0].key}"`
      },
      userEmail: localStorage.getItem('userEmail'),
      dashboard: this._dashboardName,
      page: this._pageName,
      dashlet: this._dashletType,
      queryTypeId: 6,
      startDate,
      endDate,
      pageIndex: 1,
      pageSize: 500,
      entityType
    };

    //API Issue
    return this.apiService.post('fixedFilters', payload);
    //.subscribe(data => this.setCurrencyValues(data));
  }

  setCurrencyValues(data) {
    const filterData = this.filtersData;
    data.Data = data.Data && data.Data.length ? data.Data.filter(i => i != null) : [];
    filterData['ReportingCurrency'] = data.Data.map(i => new FilterItem(i, undefined, undefined, true));
    this.filtersData = filterData;
    this.currencyUpdated.next(true);
    this._initialFilters['ReportingCurrency'].totalRecords = data.TotalRecords;
    if (this.filterSelection) {
      const filterSelection = this.filterSelection;
      filterSelection['ReportingCurrency'].totalRecords = data.TotalRecords;
      this.filterSelection = filterSelection;
    }
  }

  // Set filters formatted value for the entity depending on single select or mult-select
  getFiltersValue(filter) {
    let filterValue = '';
    let values: Array<string>;
    if (filter.name === 'TimePeriod' || filter.name === 'DateType') {
      values = filter.value.map(i => (i.key ? i.key : i));
      filterValue = values[0];
    } else if (filter.name === 'IsLuxTransaction') {
      filterValue = filter.value[0].key;
    } else if (filter.name === 'ReportingCurrency') {
      filterValue = `"${filter.value[0].key}"`;
    } else {
      values = filter.value.map(i => (i.chipName ? i.chipName : i));
      filterValue = `"${values.join('","')}"`;
    }
    // if (singleSelect) {
    //   filterValue = `"${values[0]}"`;
    // } else {
    //   filterValue = `"${values.join('","')}"`;
    // }
    return filterValue;
  }

  // Function to set selected filters values to get dashlet data
  setSelectedFilters() {
    const selectedFilters = {
      TimePeriod: null,
      DateType: null,
      timePeriodValues: null,
      appliedFilters: []
    };
    const filterSelection = this.filterSelection;
    for (const filterName in filterSelection) {
      const filter = filterSelection[filterName];
      if (filter.selected && filter.value && filter.value.length) {
        // selectedFilters[filterName] = this.getFiltersValue(filter);
        const filterValue = this.getFiltersValue(filter);
        if (filterName === 'StrongerTogether'){
          if (filterValue === '"On"'){
            this.strongerTogetherValueChanged.next('On');
          } else {
            this.strongerTogetherValueChanged.next('Off');
          }
        }
        if (filterName === 'StrongerTogetherReason'){
          this.strongerTogetherReasonChanged.next(filterValue);
        }
        if (selectedFilters[filterName] === null) {
          selectedFilters[filterName] = filterValue;
        } else {
          if (filterName === 'IsLuxTransaction' && filterValue === 'Default') {
            continue;
          }

          const appliedFilter = {
            key: filterName,
            value: filterValue,
            operator: filter.isExclude ? 'NotIN' : 'IN'
          };
          selectedFilters.appliedFilters.push(appliedFilter);
        }
      }
    }

    const dateRangeValue = filterSelection.TimePeriod.value[0];
    if (dateRangeValue.monthsList) {
      selectedFilters.timePeriodValues = dateRangeValue.monthsList;
    } else if (dateRangeValue.yearsList) {
      selectedFilters.timePeriodValues = dateRangeValue.yearsList;
    }
    this._selectedFilters = selectedFilters ? selectedFilters : {};
  }

  // Function to get the selected filters while API call
  getSelectedFilters() {
    return JSON.parse(JSON.stringify(this._selectedFilters));
  }

  getFiltersForPDF() {
    const appliedFilter = {};
    const filterSelection = this.filterSelection;
    for (const filterName in filterSelection) {
      const filter = filterSelection[filterName];
      if (filter.value && filter.value.length) {
        let values = this.getFiltersValue(filter);
        if (values == 'CY' || values == 'FY' || values == 'FYTD') {
          values = filter.value[0].chipName;
        }
        appliedFilter[filter.displayName] = values.replace(/\"/g, '');
      }
    }
    const dateRangeValue = filterSelection.DateType.value[0];
    if (dateRangeValue.monthsList) {
      appliedFilter['Months'] = dateRangeValue.monthsList.map(month => {
        month = month.toString();
        return month.slice(0, 4) + '-' + month.slice(4, 6);
      });
    } else if (dateRangeValue.yearsList) {
      appliedFilter['Years'] = dateRangeValue.yearsList;
    } else {
      appliedFilter['Start Date'] = this.getFormattedDate(this._dateRangeStartDate);
      appliedFilter['End Date'] = this.getFormattedDate(this._dateRangeEndDate);
    }
    return appliedFilter;
  }

  // returning date format equal to  dd/mm/yyyy
  getFormattedDate(date: string) {
    const day = date.substr(3, 2);
    const month = date.substr(0, 2);
    const year = date.substr(6, date.length);

    return day + '/' + month + '/' + year;
  }

  // Function to get applied filters to get values for filter dropdown
  getAppliedFilters(key = '') {
    const filters = {
      TimePeriod: null,
      DateType: null,
      timePeriodValues: null,
      appliedFilters: []
    };
    const filterSelection = this.filterSelection;
    for (const filterName in filterSelection) {
      const filter = filterSelection[filterName];
      if (filter.name !== key && filter.value && filter.value.length) {
        const filterValue = this.getFiltersValue(filter);
        if (filters[filter.name] === null) {
          filters[filter.name] = filterValue;
        } else {
          if (filterName === 'IsLuxTransaction' && filterValue === 'Default') {
            continue;
          }

          filters.appliedFilters.push({
            key: filterName,
            value: filterValue,
            operator: filter.isExclude ? 'NotIN' : 'IN'
          });
        }
      }
    }

    //Add the payload set if the user selects any of the below filters
    if (['ProducerGroupName', 'ProducerSubGroupName', 'ProducerRegion', 'ProducerCountry', 'ProducerState', 'ProducerState'].indexOf(key) > -1) {
      let producerPayload = [
        { key: 'ClientIndustrySector', value: '"Insurance"', operator: 'IN' },
        {
          key: 'ClientIndustryType',
          value:
            '"Insurance","Insurance Broker","Insurance Intermediary","MGA","Property Program","Risk Management","Risk Retention Group"',
          operator: 'IN'
        }
      ];
      filters.appliedFilters = [...filters.appliedFilters, ...producerPayload];
    }

    if (filterSelection && filterSelection.TimePeriod) {
      const dateRangeValue = filterSelection.TimePeriod.value[0];
      if (dateRangeValue.monthsList) {
        filters.timePeriodValues = dateRangeValue.monthsList;
      } else if (dateRangeValue.yearsList) {
        filters.timePeriodValues = dateRangeValue.yearsList;
      }
    }
    return filters;
  }

  // Set the optional filter dropdown data
  setOptionalFilterValues(key, data) {
    const filterData = this.filtersData;
    data.Data = data.Data && data.Data.length ? data.Data.filter(i => i != null) : [];
    filterData[key] = data;
    this.filtersData = filterData;
  }

  // Set filter when drilling down
  setDrilldownFilter(filter, value) {
    const filterSelection = this.filterSelection;
    if (filterSelection[filter]) {
      filterSelection[filter].value = [value];
      filterSelection[filter].selected = true;
    }
    this.filterSelection = filterSelection;
  }

  // Set mandatory filters selection
  updateMandatoryFilter(filter, value) {
    const filterSelection = this.filterSelection;
    if (filter === 'StrongerTogetherReason'){
      filterSelection[filter].value = [value];
    } else {
      filterSelection[filter].value[0] = value;
    }
    this.filterSelection = filterSelection;
  }

  // Set optional filter selection
  updateOptionalFilter(filter, value, isSelected = true) {
    const filterSelection = this.filterSelection;
    if (value.length > 0) {
      if (filter === 'ClientRegion' || filter === 'ClientCountry') {
        this.removeFilters(['ProducerRegion', 'ProducerState', 'ProducerCountry'], filterSelection);
      } else if (filter === 'ProducerRegion' || filter === "ProducerState" || filter === "ProducerCountry") {
        this.removeFilters(["ClientRegion", "ClientCountry"], filterSelection);
      }
    }
    setTimeout(() => {
      if (this.checkIfValueUpdated(value, filterSelection[filter].value)) {
        filterSelection[filter].value = value;
        filterSelection[filter].selected = true;
        this.filterSelection = filterSelection;
        // Reset global search key upon selection of filter value
        this.updateGlobalSearchKey('');
      }
    }, 1000);

  }

  removeFilters(filters, filterSelections) {
    const filterSelection = this.filterSelection;
    filters.map(filter => {
      filterSelections[filter].value = null;
      filterSelections[filter].selected = false;
    })

    this.filterSelection = filterSelections;
  }

  checkIfValueUpdated(newValue, presentValue) {
    if (!presentValue && newValue.length == 0) return false;
    if (!presentValue && !newValue) {
      return false;
    }
    if (
      (!presentValue && newValue && newValue.length) ||
      (!newValue && presentValue && presentValue.length) ||
      newValue.length !== presentValue.length
    ) {
      return true;
    }
    for (const item of presentValue) {
      if (newValue.indexOf(item) < 0) {
        return true;
      }
    }
    // presentValue.forEach(item => {
    // });
    return false;
  }

  // Set the selected status of the filter
  filterStatusChanged(filter, status) {
    const filterSelection = this.filterSelection;
    filterSelection[filter].selected = status;
    this.filterSelection = filterSelection;
  }

  // Save filters is basically apply filter selections to chips and update dashlets
  saveFilters(updateDashlets = true) {
    const filters = this.filterSelection;
    const appliedFilter = [];
    for (const key in filters) {
      if (filters[key].selected && filters[key].value && filters[key].value.length) {
        const item = { ...filters[key] };
        if (item.name === 'TimePeriod'){
          if (item.value[0].chipName === 'CY'){
            item.value[0].chipName = 'Calendar Year';
          }
        }
        item.value = item.value.map(i => (i.chipName ? i.chipName : i));
        appliedFilter.push(item);
      }
    }

    this.appliedFilter.next(appliedFilter);
    this.setSelectedFilters();
    this.setFilterGroupCount();
    if (!updateDashlets) {
      return;
    }
    setTimeout(() => {
      this.updateDashletData.next({ type: true, filters: appliedFilter });
      this.updateGlobalSearchKey('');
    });
  }

  removeStrongerTogeteherPolicyFilters(updateDashlets = true){
    const filters = this.filterSelection;
    const appliedFilter = [];
    for (const key in filters) {
      if (filters[key].selected && filters[key].value && filters[key].value.length) {
        const item = { ...filters[key] };
        let strongerTogetherFilters :string[] = ['PolicyRegion', 'PolicyCompany', 'PolicyDivision', 'PolicyTeam', 'PolicySubTeam', 'PolicyBase', 'DisplayLevel1', 'DisplayLevel2', 'DisplayLevel3']
        if (strongerTogetherFilters.indexOf(item.name) < 0){
          item.value = item.value.map(i => (i.chipName ? i.chipName : i));
          appliedFilter.push(item);
        }else {
          this.chipsUpdated.next({ filterName: item.name })
          this.updateOptionalFilter(item.name, []);
          this.filterStatusChanged(item.name, false);
        }
      }
    }

  }

  removeStrongerTogeteherReason(updateDashlets = true){
    const filters = this.filterSelection;
    const appliedFilter = [];
    for (const key in filters) {
      if (filters[key].selected && filters[key].value && filters[key].value.length) {
        const item = { ...filters[key] };
        let strongerTogetherFilters :string[] = ['StrongerTogetherReason']
        if (strongerTogetherFilters.indexOf(item.name) < 0){
          item.value = item.value.map(i => (i.chipName ? i.chipName : i));
          appliedFilter.push(item);
        }else {
          this.chipsUpdated.next({ filterName: item.name })
          this.updateOptionalFilter(item.name, []);
          this.filterStatusChanged(item.name, false);
        }
      }
    }

  }

  getseletedFilterFromChips(keyList, triggerDashletsData = true) {
    const filterSelection = this.filterSelection;
    keyList.map((list, index) => {
      if (index < keyList.length - 1)
        this.removeChip(filterSelection[list.key], true, list.index)
    })
  }

  changeStrongerTogetherValue(value, strongerTogetherReason){
    var obj = {};
    if (value === 'Off'){
      obj = {chipName: "On", disabled: false, displayName: "On", filterChipName: "On", filterkey: "On", filtervalue: "On", key: "On",singleSelect: false}
      const strongerTogetherReasonChip = this.changeChipForStrongerTogetherReason(strongerTogetherReason);
      this.updateMandatoryFilter('StrongerTogetherReason', strongerTogetherReasonChip);
    } else {
      obj = {chipName: "Off", disabled: false, displayName: "Off", filterChipName: "Off", filterkey: "Off", filtervalue: "Off", key: "Off",singleSelect: false}
    }
    this.updateMandatoryFilter('StrongerTogether', obj);
    this.strongerTogetherValueChanged.next(obj['displayName']);
    if(obj['displayName'] === 'Off'){
      this.removeStrongerTogeteherPolicyFilters(true);
      this.removeStrongerTogeteherReason(true);
      this.saveFilters(true);
    } else{
      this.saveFilters(true);
    }
  }

  changeStrongerTogetherReasonValue(value){
    const obj = this.changeChipForStrongerTogetherReason(value);
    this.updateMandatoryFilter('StrongerTogetherReason', obj);
    const filterSelection = this.filterSelection;
    this.filterSelection = filterSelection;
    this.saveFilters(true);
  }

  changeChipForStrongerTogetherReason(value) {
    var obj = {};
    if (value === 'DiffBU') {
      obj = {
        chipName: "DiffBU", disabled: false, displayName: "DiffBU", filterChipName: "DiffBU",
        filterkey: "DiffBU", filtervalue: "DiffBU", key: "DiffBU", singleSelect: false
      };
    } else if (value === 'Howden Client/TP') {
      obj = {
        chipName: "Howden Client/TP", disabled: false, displayName: "Howden Client/TP", filterChipName: "Howden Client/TP",
        filterkey: "Total", filtervalue: "Howden Client/TP", key: "HowdenClientTP", singleSelect: false
      };
    } else {
      obj = {
        chipName: "Total", disabled: false, displayName: "Total", filterChipName: "Total",
        filterkey: "Total", filtervalue: "Total", key: "Total", singleSelect: false
      };
    }
    return obj;
  }

  // Remove the the chip for the chips set, filter panel as well as update dashlet
  removeChip(data, triggerDashletsData = true, index?) {
    this.chipsUpdated.next({ filterName: data.displayName })
    this.updateOptionalFilter(data.name, []);
    this.filterStatusChanged(data.name, false);
    this.saveFilters(triggerDashletsData);
    const levelConfig = this.levelConfigs.filter(level => level.name === data.name)[0];
    if (levelConfig){
      this.goBackLevelForDashlet.next(levelConfig);
    }
  }

  removeFilterValueWithDrillUp(data) {
    this.chipsUpdated.next({ filterName: data.displayName })
    this.updateOptionalFilter(data.name, []);
    this.filterStatusChanged(data.name, false);
  }

  // Function to get config for last year
  getLastDaysYearConfig() {
    // const startDate = moment().subtract(1, 'years').format('MM/DD/YYYY');
    // const endDate = moment().subtract(1, 'days').format('MM/DD/YYYY');

    const startDate = moment().subtract(1, 'years').startOf('month').format('MM/DD/YYYY');
    const endDate = moment().subtract(1, 'months').endOf('month').format('MM/DD/YYYY');
    return { startDate, endDate };
  }
  getAllValuesConfig() {
    const startDate = moment('1900-01-01').format('MM/DD/YYYY');
    const endDate = moment()
      .add(1, 'days')
      .format('MM/DD/YYYY');
    return { startDate, endDate };
  }

  // Function to get x days data
  getNextDaysConfig(days = 180) {
    const today = moment();
    const startDate = today.add(1, 'days').format('MM/DD/YYYY');
    const endDate = moment()
      .add(days, 'days')
      .format('MM/DD/YYYY');
    return { startDate, endDate };
  }

  // Set the date range type and value
  setDateRangeValues(filter, dateData) {
    let filterValue;
    if (typeof dateData.value === 'string') {
      filterValue = {
        disabled: false,
        singleSelect: false,
        key: dateData.value,
        displayName: dateData.value,
        chipName: dateData.displayName
      };
      if (dateData.monthValue) {
        filterValue.monthsList = dateData.monthValue;
      }
      if (dateData.yearValue) {
        filterValue.yearsList = dateData.yearValue;
      }
    }
    this.dateData=dateData;
    this.updateMandatoryFilter(filter, filterValue);
    this._dateRangeEndDate = dateData.endDate;
    this._dateRangeStartDate = dateData.startDate;
  }

  // Function to get current date range values
  getDateRangeValues() {
    return {
      startDate: this._dateRangeStartDate,
      endDate: this._dateRangeEndDate
    };
  }

  getSelectedFilterDateRange() {
    return {
      startDate: this._selectedFilterSetName.filterList.startDate,
      endDate: this._selectedFilterSetName.filterList.endDate
    };
  }

  toggelFilterPanel() {
    this.filterOpened.next(!this.filterOpened.value);
  }

  closeFilterPanel() {
    this.filterOpened.next(false);
  }

  openFilterPanel() {
    this.filterOpened.next(true);
  }

  /* Refactored Code for Filters */

  // Initialise all the data
  setInitialValues() {
    this.initFilterSelectionAndValues();
    let filters = FIXED_FILTERVALUES_MOCK;
    if (this.showTwoRateOptions){
      if (this.isRenewalPage) {
        filters = { ...FIXED_FILTERVALUES_MOCK, ...RENEWAL_FILTERS };
      }
    } else if (this.showPreviousYearOnly){
      filters = FIXED_FILTERVALUES_MOCK_ONLY_PREV;
      if (this.isRenewalPage) {
        filters = { ...FIXED_FILTERVALUES_MOCK_ONLY_PREV, ...RENEWAL_FILTERS };
      }
    } else {
      filters = FIXED_FILTERVALUES_MOCK_ONLY_CURR;
      if (this.isRenewalPage) {
        filters = { ...FIXED_FILTERVALUES_MOCK_ONLY_CURR, ...RENEWAL_FILTERS };
      }
    }
    this.setFixedFiltersData(filters);
  }

  // Create filter selection data and filters value object mapping
  initFilterSelectionAndValues() {
    // Get the config from file
    const filterConfigArr = FILTERS_CONFIG.map(item => item.child);
    const filterConfig = [].concat(...filterConfigArr);
    // Create the initial data mapping
    const filterSelection = {};
    const filtersData = {};
    filterConfig.map(item => {
      filtersData[item.key] = null;
      filterSelection[item.key] = new FilterChip(item.key, item.displayName, item.isSingleSelect, null);
    });
    this._initialFilters = filterSelection;
    this.filtersData = filtersData;
  }

  // Set fixed filters data for dropdown
  setFixedFiltersData(fixedFilterValues) {
    const filtersData = this.filtersData;
    const filterSelection = this.filterSelection;
    for (const filterKey in fixedFilterValues) {
      const filterItem = fixedFilterValues[filterKey];
      // Set fixed filters dropdown data
      filtersData[filterKey] = filterItem.Data.map(i => new FilterItem(i));
      // Set fixed filters mandatory data value
      const defaultSelection = filterItem.Data.find(i => i.IsDefault);
      const name = this._initialFilters[filterKey].displayName;
      this._initialFilters[filterKey] = new FilterChip(
        filterKey,
        name,
        filterItem.IsSingleSelect,
        defaultSelection,
        filterItem.Data.length,
        false
      );
      this._initialFilters[filterKey].displayName = name;
      // Set filter selection if its present
      if (filterSelection && filterSelection[filterKey]) {
        filterSelection[filterKey] = JSON.parse(JSON.stringify(this._initialFilters[filterKey]));
      }
    }
    this.filtersData = filtersData;
    this.filterSelection = filterSelection;
  }

  clearFilterSelection() {
    this.filterSelection = JSON.parse(JSON.stringify(this._initialFilters));
    this.saveFilters();
    this.resetBreadCrumbForAllDashlets.next(true);
  }

  setFilterGroupCount() {
    const groups = this.filterGroups;
    groups.forEach(groupItem => {
      groupItem.count = groupItem.child.reduce((acc, childItem1) => {
        if (this.filterSelection && this.filterSelection[childItem1.key] && this.filterSelection[childItem1.key].selected) {
          return acc + 1;
        } else {
          return acc;
        }
      }, 0);
    });
    this.filterGroups = groups;
  }

  updateFilterGroupStatus(filter: any, status: any, index: number) {
    const groups = this.filterGroups;
    if (status) {
      groups[index].count++;
    } else {
      groups[index].count--;
    }
    this.filterGroups = groups;
    this.filterStatusChanged(filter, status);
  }

  /* Logic for global search with count */
  updateGlobalSearchKey(searchKey) {
    this.globalSearchKey.next(searchKey);
    this.filterCountSub.next(searchKey);
  }

  // API to get filters count
  getFiltersCount(searchKey) {
    if (searchKey == null || !this._initialFilters || !Object.keys(this._initialFilters).length) {
      return;
    }
    this.filterCountLoading.next({ status: true });
    const entitiesList = FILTER_COUNT_DASHLET_CONFIG;
    let appliedFilter = this.getAppliedFilters();
    appliedFilter = this.getFYRate(appliedFilter);
    const entitiesListAPI = entitiesList.map(entity => this.getSeperateFilterCount(searchKey, entity, appliedFilter));
    const entitiesJoin = forkJoin(entitiesListAPI).subscribe(data => {
      this.updateFilterCount(data);
      entitiesJoin.unsubscribe();
    });
  }

  // Function to get payloads for all entity breakups
  getSeperateFilterCount(searchKey, dashlet, appliedFilters) {
    const payload = {
      searchKey,
      userEmail: localStorage.getItem('userEmail'),
      dashboard: this._dashboardName,
      page: 'Filters',
      dashlet,
      queryTypeId: 1,
      pageIndex: 1,
      pageSize: 500,
      startDate: this._dateRangeStartDate,
      endDate: this._dateRangeEndDate,
      entityType: '',
      ...appliedFilters
    };

    if (payload['TimePeriod'] == 'CY') {
      appliedFilters['startDate'] = this.FYCYDates.startDate
      appliedFilters['endDate'] = this.FYCYDates.endDate
      if (payload['timePeriodValues'] != null && payload['timePeriodValues'].includes(moment().format('YYYY'))) {
        payload['timePeriodValues'] = payload['timePeriodValues'].split(',').filter(item => item != moment().format('YYYY')).join()
      }
    } else if (payload['TimePeriod'] == 'FY') {
      appliedFilters['startDate'] = this.FYCYDates.startDate
      appliedFilters['endDate'] = this.FYCYDates.endDate
      if (payload['timePeriodValues'] != null && payload['timePeriodValues'].includes(moment().add(1, 'years').format('YYYY'))) {
        payload['timePeriodValues'] = payload['timePeriodValues'].split(',').filter(item => item != moment().add(1, 'years').format('YYYY')).join();
      }
    }
    else {
      payload['timePeriodValues'] = null;
    }


    // Remove Producer Region, Producer Country and Producer State while getting count for Client Region and Client Country dropdowns
    if (payload.dashlet === 'CrossFilterCountClientRegion') {
      let removedPayload = payload.appliedFilters.filter(filterObj => {
        return !["ProducerRegion", "ProducerCountry", "ProducerState"].includes(filterObj.key);
      })
      payload.appliedFilters = [...removedPayload]
      // Remove Client Country and Client State while getting count for Producer Region,Producer Country and Producer state dropdowns
    } else if (payload.dashlet === 'CrossFilterAllCountProdRegion' || payload.dashlet === 'CrossFilterAllCountProducerState') {
      let removedPayload = payload.appliedFilters.filter(filterObj => {
        return !["ClientRegion", "ClientCountry"].includes(filterObj.key);
      })
      payload.appliedFilters = [...removedPayload]
    }
    return this.apiService.post('filterCount', payload);
  }

  // function to update the count
  updateFilterCount(countData) {
    const filterSelection = this.filterSelection;
    countData.forEach(data => {
      if (!data) {
        return;
      }
      const separateCountData = data[0];
      for (const key in separateCountData) {
        if (filterSelection[key]) {
          filterSelection[key].totalRecords = separateCountData[key];
        }
      }
    });
    this.filterSelection = filterSelection;
    this.filterCountLoading.next({ status: false });
  }

  /* Logic for global search with count */

  // Function to reset few filters when route is changed
  /*
  Update
  Date Range, Filter dropdown data, initial value, filter selection, saved filters
  */
  resetTimeDateFilter(isRenewalPage) {
    this.updateDashletData.next({ type: false });
    this.isRenewalPage = isRenewalPage;
    const resetFilter = isRenewalPage ? RENEWAL_FILTERS : OTHER_PAGE_FILTERS;
    if (!this._initialFilters || !Object.keys(this._initialFilters).length) {
      return;
    }
    this.setFixedFiltersData(resetFilter);
    this.savedFilters = isRenewalPage ? this._renewalSavedList : this._nonRenewalSavedList;
    if (this.selectedFilter.value) {
      this.saveFilters();
    }
    this.filtersLoaded.next({ status: true });
  }

  /* Refactored Code for Filters */

  /* Save Selected Filters Logic */

  // Function to get the pageNames to be used in saved filters API
  getRelevantPageName() {
    let pageNames;
    pageNames = PAGES_TAB.map(i => i.pageName).join(',');
    return pageNames;
  }

  // Function to get list of all Saved Filters for User
  getSavedFiltersList() {
    const payload = {
      userName: localStorage.getItem('userEmail'),
      pageNames: ''
    };
    this.apiService
      .get('savedFiltersList', payload)
      .pipe(
        map(data => new SavedFilters(data)),
        map(data => this.updateSavedListForPages(data))
      )
      .subscribe(data => this.handleUpdatedSavedFilters(data));
  }

  setAsDefaultSavedFilter(filter) {
    const payload = {
      userEmail: localStorage.getItem('userEmail'),
      filterSetName: filter.setName,
      filterSet: JSON.stringify(filter.filterList),
      isDefault: filter.isDefault,
      pageNames: 'Producers&Markets'
    };
    this.apiService
      .post('saveNewFilter', payload)
      .pipe(
        map(data => new SavedFilters(data)),
        map(data => this.updateSavedListForPages(data))
      )
      // Update the selection, if the new filter set is saved
      .subscribe(data => console.log('saved set as default'));
  }

  // Function to Add filter Selection to Saved Filters List
  addSelectionToSavedFilters(filter) {
    const financialYear = this.GetCurrentFinancialYear();
    const selectedRateType = filter?.filterList?.appliedFilters.filter(item => item.key === 'RateType')[0]?.value;
    if (selectedRateType.includes('Group Annual')){
      filter.filterList.appliedFilters.filter(item => item.key === 'RateType')[0].value = 'Group Annual Budget FY' + financialYear;
    } else if (selectedRateType.includes('HIBL Annual')){
      filter.filterList.appliedFilters.filter(item => item.key === 'RateType')[0].value = 'HIBL Annual Budget FY' + financialYear;
    }
    const payload = {
      userEmail: localStorage.getItem('userEmail'),
      filterSetName: filter.setName,
      filterSet: JSON.stringify(filter.filterList),
      isDefault: filter.isDefault,
      pageNames: 'Producers&Markets'
    };
    this.apiService
      .post('saveNewFilter', payload)
      .pipe(
        map(data => new SavedFilters(data)),
        map(data => this.updateSavedListForPages(data))
      )
      // Update the selection, if the new filter set is saved
      .subscribe(data => (this.selectedFilterSetName = filter));
  }

  // Delete the filter selection from Saved Filters List
  deleteFilterFromSavedFilters(filter) {
    const payload = {
      userEmail: localStorage.getItem('userEmail'),
      filterSetName: filter.setName,
      filterSet: JSON.stringify(filter.filterList),
      isDefault: filter.isDefault,
      pageNames: 'Producers&Markets'
    };
    this.apiService
      .post('deleteSavedFilter', payload)
      .pipe(
        map(data => new SavedFilters(data)),
        map(data => this.updateSavedListForPages(data))
      )
      .subscribe(data => {
        // This for the case if we delete the existing applied filter
        // if (this.selectedFilterSetName.setName === filter.setName) {
        this.handleUpdatedSavedFilters(data);
        // }
      });
  }

  getFYRate(filters) {
    if (filters.appliedFilters !== null && filters.appliedFilters !== undefined){
      const financialYear = this.GetCurrentFinancialYear();
      const selectedRateType = filters.appliedFilters.filter(item => item.key === 'RateType')[0].value;
      if (selectedRateType.includes('Group Annual') || selectedRateType.includes('HIBL Annual')) {
        filters.appliedFilters = filters.appliedFilters.map(item => {
          if (item.key === 'RateType') {
            if (item.value.includes('Group Annual Budget')){
              if (item.value.includes(financialYear)){
                filters['financialYear'] = financialYear;
                item.value = '"Group Annual Budget Current FY"';
              } else {
                filters['financialYear'] = financialYear - 1;
                item.value = '"Group Annual Budget Previous FY"';
              }
            }
            if (item.value.includes('HIBL Annual Budget')){
              if (item.value.includes(financialYear)){
                filters['financialYear'] = financialYear;
                item.value = '"HIBL Annual Budget Current FY"';
              } else {
                filters['financialYear'] = financialYear - 1;
                item.value = '"HIBL Annual Budget Previous FY"';
              }
            }
          }
          return item;
        });
      }
    }
    return filters;
  }

  applyFilterSet(filterSet) {
    if (filterSet.filterList.appliedFilters !== null && filterSet.filterList.appliedFilters !== undefined){
      const financialYear = this.GetCurrentFinancialYear();
      let selectedRateType = filterSet.filterList.appliedFilters.filter(item => item.key === 'RateType')[0].value;
      if (selectedRateType.includes('Group Annual') ){
        filterSet.filterList['financialYear'] = financialYear;
        filterSet.filterList.appliedFilters.filter(item => item.key === 'RateType')[0].value = 'Group Annual Budget FY' + financialYear;
      } else if (selectedRateType.includes('HIBL Annual')){
        filterSet.filterList['financialYear'] = financialYear;
        filterSet.filterList.appliedFilters.filter(item => item.key === 'RateType')[0].value = 'HIBL Annual Budget FY' + financialYear;
      }
    }
    this.selectedFilterSetName = filterSet;
    this.setSelectedFilterSet(filterSet);
    this.saveFilters();
    this.filtersLoaded.next({ status: true });
  }

  updateSavedListForPages(filterSet) {
    this._renewalSavedList = filterSet.renewalFilters.map(i => this.formatDisplayList(i));
    this._nonRenewalSavedList = filterSet.nonRenewalFilters.map(i => this.formatDisplayList(i));
    this.savedFilters = this.isRenewalPage ? this._renewalSavedList : this._nonRenewalSavedList;
    return this.savedFilters;
  }

  // Handle the recieved list of filters
  handleUpdatedSavedFilters(filtersList) {
    let defaultFilterSet;
    if (filtersList && filtersList.length) {
      defaultFilterSet = filtersList.find(i => i.isDefault);
    }
    // If default set present then set default
    if (defaultFilterSet) {
      this.selectedFilterSetName = defaultFilterSet;
      const financialYear = this.GetCurrentFinancialYear();
      const selectedRateType = defaultFilterSet?.filterList?.appliedFilters.filter(item => item.key === 'RateType')[0]?.value;
      if (selectedRateType.includes('Group Annual')){
        defaultFilterSet.filterList.appliedFilters.filter(item => item.key === 'RateType')[0].value = 'Group Annual Budget FY' + financialYear;
      } else if (selectedRateType.includes('HIBL Annual')){
        defaultFilterSet.filterList.appliedFilters.filter(item => item.key === 'RateType')[0].value = 'HIBL Annual Budget FY' + financialYear;
      }
      this.setSelectedFilterSet(defaultFilterSet);
    } else {
      this.selectedFilterSetName = null;
      this.setMandatoryFilterSet();
    }
    const savedFilterSet = filtersList.find(filterSetItem=> {
      return filterSetItem.isDefault;
    })
    if (savedFilterSet !== undefined){
      const strongerTogetherAppliedFilter = savedFilterSet.filterList?.appliedFilters.find(appliedFilterItem=> {
        return appliedFilterItem.key === 'StrongerTogether';
      });
      if (strongerTogetherAppliedFilter !== undefined){
        if (strongerTogetherAppliedFilter.value === '"On"'){
          this.strongerTogetherValueChanged.next('On');
          const strongerTogetherReasonAppliedFilter = savedFilterSet.filterList?.appliedFilters.find(appliedFilterItem=> {
            return appliedFilterItem.key === 'StrongerTogetherReason';
          });
          this.strongerTogetherReasonChanged.next(strongerTogetherReasonAppliedFilter.value);
        }
      }
    }
    this.saveFilters();
    this.getCurrencyValues();
    this.filtersLoaded.next({ status: true });
  }

  private GetCurrentFinancialYear() {
    const date = new Date();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    let fullFinancialYear = (month >= 10 && month <= 12) ? year + 1 : year;
    if (this.showPreviousYearOnly && this.mapRateTypeFYTo === 'CY' && !this.showTwoRateOptions){
        fullFinancialYear--;
    }
    const financialYear = Number(fullFinancialYear.toString().slice(-2));
    return financialYear;
  }

  // Set Mandatory Filters
  setMandatoryFilterSet() {
    this.filterSelection = JSON.parse(JSON.stringify(this._initialFilters));
  }

  // Set the filter Selection provided as input
  setSelectedFilterSet(filtersList) {
    const filters = filtersList.filterList;
    const filterSelection = JSON.parse(JSON.stringify(this._initialFilters));
    for (const filterKey in filters) {
      const filterValue = filters[filterKey];
      let newValue;
      if (filterKey === 'appliedFilters') {
        filterValue.forEach(item => {
          const value = item.value.replace(/"/g, '');
          if (!filterSelection[item.key].isSingleSelect) {
            filterSelection[item.key].selected = true;
            filterSelection[item.key].value = value.split(',');
          } else {
            filterSelection[item.key].value = [new FilterItem(value)];
          }
        });
        continue;
      } else if (filterKey === 'TimePeriod' && /(FY|CY|Next days)/i.test(filterValue)) {
        newValue = new FilterItem(filterValue.split(' - ')[0]);
        if (filterValue === 'CY'){
          newValue.chipName = 'Calendar Year';
        } else {
          newValue.chipName = filterValue;
        }
        filterSelection[filterKey].value = [newValue];
      } else if (filterValue && filterSelection[filterKey]) {
        filterSelection[filterKey].value = [new FilterItem(filterValue)];
      }
    }
    if (filters.TimePeriod === 'Months' && filters.MonthsOrYears) {
      filterSelection.TimePeriod.value[0].monthsList = filters.timePeriodValues;
    }
    if ((filters.TimePeriod === 'CY' || filters.TimePeriod === 'FY') && filters.timePeriodValues) {
      filterSelection.TimePeriod.value[0].yearsList = filters.timePeriodValues;
    }
    this.filterSelection = filterSelection;
  }

  // Function to convert the filter set sent into the display format
  formatDisplayList(filter) {
    const selections = filter.filterList;
    const selectionList = this._initialFilters;
    let displayFormat = '';
    for (const key in selections) {
      if (key === 'appliedFilters') {
        const appliedDisplay = selections[key].map(i => `${selectionList[i.key].displayName}: ${i.value}`).join('; ');
        displayFormat += appliedDisplay;
      } else if (selectionList[key]) {
        displayFormat += `${selectionList[key].displayName}: ${selections[key]  =='Last 365 Days'?'Last 12 months':selections[key]}; `;
      }
    }
    filter.filterDisplayList = displayFormat.replace(/\"/g, '');
    return filter;
  }

  // Filters format for saved selection needs to be applied filters with date ranges
  getFilterFormatForSavedFilters() {
    let filters = this.getAppliedFilters();
    filters = this.getFYRate(filters);
    const dateRange = this.getDateRangeValues();
    filters = { ...filters, ...dateRange };
    return filters;
  }

  // Initial format for the save filter set
  initSaveFilterFormat() {
    const filterObj = {
      setName: null,
      filterList: this.getFilterFormatForSavedFilters(),
      filterDisplayList: '',
      isDefault: false
    };
    return this.formatDisplayList(filterObj);
  }

  // Check if the name of the filter is valid
  checkIfNameIsValid(name) {
    const filtersList = this.savedFilters;
    const findDuplicateName = filtersList.find(item => item.setName === name);
    return findDuplicateName == null && name.length > 0;
  }

  // Function to get the months list for renewals
  getMonthsListForRenewal() {
    const payload = {
      userEmail: localStorage.getItem('userEmail'),
      dashboard: DASHBOARD_NAME,
      page: 'Filters',
      dashlet: 'MonthYear',
      dashletTypeId: 1
    };
    this.apiService.get('monthsListForRenewal', payload).subscribe(
      data => this.renewalMonthsList.next(data),
      err => console.log('Error in getting renewal months list', err)
    );
  }

  getYearList(dashlet) {
    const selectedTimePeriodFilter = this.selectedFilter.value['DateType'].value[0].key;
    const payload = {
      userEmail: localStorage.getItem('userEmail'),
      dashboard: DASHBOARD_NAME,
      page: 'Filters',
      dashlet,
      queryTypeId: 1,
      dateType: selectedTimePeriodFilter
    };
    return this.apiService.get('yearList', payload);
  }

  /* Save Selected Filters Logic */

  /* Commented old code */

  // setFilterValuesAndSelections(configData) {
  //   const filterSelection = {};
  //   const filtersData = {};
  //   configData.map(item => {
  //     filtersData[item.key] = null;
  //     filterSelection[item.key] = new FilterChip(item.key, item.displayName, item.isSingleSelect, null);
  //   });
  //   this.filterSelection = filterSelection;
  //   this.filtersData = filtersData;
  // }

  // Function to get fixed filters values
  // getFixedFiltersValues() {
  //   let filters = FIXED_FILTERVALUES_MOCK;
  //   if (this.isRenewalPage) {
  //     filters = { ...FIXED_FILTERVALUES_MOCK, ...RENEWAL_FILTERS };
  //   }
  //   this.setDateRange();
  //   this.setFixedFiltersValues(filters);
  // }

  // setFixedFiltersValues(newFilterData) {
  //   const filterSelection = this.filterSelection;
  //   const filtersData = this.filtersData;
  //   const config = [];

  //   // For all the filters in the config, intialize the filters data and selection
  //   for (const filterKey in newFilterData) {
  //     const filterItem = newFilterData[filterKey];
  //     const defaultSelection = filterItem.Data.find(i => i.IsDefault);
  //     filtersData[filterKey] = filterItem.Data.map(i => new FilterItem(i));
  //     filterSelection[filterKey] =
  //        new FilterChip(filterKey, filterItem.DisplayName, filterItem.IsSingleSelect, defaultSelection, 0, false);
  //   }

  //   // Set the filters data and selection to be updated everywhere and save initial filters
  //   this.filtersData = filtersData;
  //   this.filterSelection = filterSelection;
  //   this._initialFilters = JSON.parse(JSON.stringify(filterSelection));
  //   this.saveFilters();
  //   this.filtersLoaded.next(true);
  //   // return config;
  // }

  // Function to reset few filters when route is changed
  // resetTimeDateFilter(isRenewalPage) {
  //   this.isRenewalPage = isRenewalPage;
  //   const resetFilter = isRenewalPage ? RENEWAL_FILTERS : OTHER_PAGE_FILTERS;
  //   this.setDateRange();
  //   const filterSelection = this.filterSelection;
  //   const filtersData = this.filtersData;
  //   for (const filterKey in resetFilter) {
  //     const filterItem = resetFilter[filterKey];
  //     const defaultSelection = filterItem.Data.find(i => i.IsDefault);
  //     filtersData[filterKey] = filterItem.Data.map(i => new FilterItem(i));
  //     filterSelection[filterKey]
  //        = new FilterChip(filterKey, filterItem.DisplayName, filterItem.IsSingleSelect, defaultSelection, 0, false);
  //     this._initialFilters[filterKey] = new FilterChip(
  //       filterKey,
  //       filterItem.DisplayName,
  //       filterItem.IsSingleSelect,
  //       defaultSelection,
  //       0,
  //       false
  //     );
  //   }
  //   this.filtersData = filtersData;
  //   this.filterSelection = filterSelection;
  //   this.saveFilters();
  //   this.filtersLoaded.next(true);
  // }

  // resetFilters() {
  //   this.filterSelection = JSON.parse(JSON.stringify(this._initialFilters));
  // }

  // Function to set initial date range values
  // setDateRange() {
  //   const dateConfig = this.isRenewalPage ? this.getNextDaysConfig() : this.getLastDaysYearConfig();
  //   this._dateRangeStartDate = dateConfig.startDate;
  //   this._dateRangeEndDate = dateConfig.endDate;
  // }

  /* Commented old code */
}
